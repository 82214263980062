import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Builder from "../components/builder"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const {
    builder,
    metaTitle,
    metaDescription,
    metaImage,
  } = data.swapi.performanceMarketinguSingleton
  return (
    <Layout
      pageContext={pageContext}
      nameClass="mozliwosci-big-data-w-performance-marketingu servicesPage"
    >
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="en"
      />
      <Builder data={builder} pageContext={pageContext} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query performanceMarketinguEN {
    swapi {
      performanceMarketinguSingleton(lang: "en") {
        builder
        metaDescription
        metaTitle
        metaImage {
          path
        }
      }
    }
  }
`
